var render = function render(){
  var _vm$board, _vm$board$category, _vm$board2, _vm$board3, _vm$board4, _vm$board5, _vm$board6, _vm$board7, _vm$board8, _vm$board9, _vm$board10;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board__view mb-40 mb-lg-60"
  }, [_c('div', {
    staticClass: "board__view__row"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align-md": "center",
      "justify-md": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(_vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : (_vm$board$category = _vm$board.category) === null || _vm$board$category === void 0 ? void 0 : _vm$board$category.name))])], 1), _c('v-col', [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.subject) + " ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end align-center"
  }, [_c('i', {
    staticClass: "icon icon-clock mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.createdAt).format("YYYY.MM.DD")) + " ")])])])], 1)], 1), _c('div', {
    staticClass: "board__view__row"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [(_vm$board4 = _vm.board) !== null && _vm$board4 !== void 0 && _vm$board4.user ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('p', {
    staticClass: "page-text--sm grey-9--text mr-4 mr-lg-8"
  }, [_vm._v("작성자 :")]), _c('board-user-level', {
    attrs: {
      "user": (_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : _vm$board5.user,
      "textStyle": "page-text--sm grey-9--text"
    }
  })], 1) : _vm._e()]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-lg-n6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-count', {
    attrs: {
      "recommend": "",
      "viewCount": (_vm$board6 = _vm.board) === null || _vm$board6 === void 0 ? void 0 : _vm$board6.viewCount,
      "likeCount": (_vm$board7 = _vm.board) === null || _vm$board7 === void 0 ? void 0 : _vm$board7.likeCount,
      "dislikeCount": (_vm$board8 = _vm.board) === null || _vm$board8 === void 0 ? void 0 : _vm$board8.dislikeCount,
      "commentCount": (_vm$board9 = _vm.board) === null || _vm$board9 === void 0 ? void 0 : _vm$board9.commentCount
    }
  })], 1), _c('v-col', {
    staticClass: "pa-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    staticClass: "pa-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-n2",
    attrs: {
      "icon": "",
      "x-small": "",
      "text": "",
      "color": "grey-9"
    }
  }, [_c('icon-bookmark', {
    attrs: {
      "_board": _vm._board,
      "code": _vm.board.code
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-n2",
    attrs: {
      "icon": "",
      "x-small": "",
      "text": "",
      "color": "grey-9"
    }
  }, [_c('icon-share')], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "board__view__row board__contents"
  }, [_c('div', {
    staticClass: "board__content mb-20 mb-lg-40",
    domProps: {
      "innerHTML": _vm._s((_vm$board10 = _vm.board) === null || _vm$board10 === void 0 ? void 0 : _vm$board10.content)
    }
  }), _c('board-recommend', {
    attrs: {
      "board": _vm.board
    },
    on: {
      "init": _vm.set
    }
  })], 1)]), _c('board-comment')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }