<template>
    <div>
        <div class="board__view mb-40 mb-lg-60">
            <div class="board__view__row">
                <v-row class="row--x-small" align-md="center" justify-md="space-between">
                    <v-col cols="12" md="">
                        <v-row class="row--x-small">
                            <v-col cols="auto">
                                <v-chip color="primary-50" text-color="primary">{{ board?.category?.name }}</v-chip>
                            </v-col>
                            <v-col>
                                <p class="page-text font-weight-medium">
                                    {{ board?.subject }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <div class="d-flex justify-end align-center">
                            <i class="icon icon-clock mr-4 mr-lg-8"></i>
                            <p class="page-text page-text--sm grey-9--text">
                                {{ $dayjs(board?.createdAt).format("YYYY.MM.DD") }}
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="board__view__row">
                <v-row align="center" class="row--x-small">
                    <!-- 개발확인: 리커버명 노출 확인 부탁드립니다 -->
                    <v-col cols="12" sm="">
                        <div v-if="board?.user" class="d-flex align-center">
                            <p class="page-text--sm grey-9--text mr-4 mr-lg-8">작성자 :</p>
                            <board-user-level :user="board?.user" textStyle="page-text--sm grey-9--text" />
                        </div>
                    </v-col>
                    <v-col cols="12" sm="auto">
                        <v-row align="center" class="ma-lg-n6">
                            <v-col cols="auto" class="pa-lg-6">
                                <board-count recommend :viewCount="board?.viewCount" :likeCount="board?.likeCount" :dislikeCount="board?.dislikeCount" :commentCount="board?.commentCount" />
                            </v-col>
                            <v-col cols="auto" class="pa-lg-6">
                                <span class="line"></span>
                            </v-col>
                            <v-col cols="auto" class="pa-lg-6">
                                <!-- 북마크 등록 시 'v-btn--active' 클래스 추가 부탁드립니다 -->
                                <v-btn icon x-small text color="grey-9" class="ma-n2">
                                    <icon-bookmark :_board="_board" :code="board.code"/>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto" class="pa-lg-6">
                                <v-btn icon x-small text color="grey-9" class="ma-n2">
                                    <icon-share />
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
            <div class="board__view__row board__contents">
                <!-- S::마이페이지에서 노출 -->
                <!-- <div class="mb-20 mb-lg-40">
                    <v-row class="row--x-small">
                        <v-spacer />
                        <v-col cols="auto">
                            <v-btn color="grey-6" class="h-lg-40px">
                                <span class="white--text font-size-14">수정</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="grey-b3" class="h-lg-40px">
                                <span class="grey-6--text font-size-14">삭제</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div> -->
                <!-- E::마이페이지에서 노출 -->

                <div v-html="board?.content" class="board__content mb-20 mb-lg-40"></div>

                <board-recommend :board="board" @init="set"/>
            </div>
        </div>

        <board-comment />
    </div>
</template>

<script>
import api from "@/api";
import BoardTit from "@/components/client/board/board-item__tit.vue";
import BoardCount from "@/components/client/board/board-count.vue";
import BoardComment from "@/components/client/board/board-comment.vue";
import BoardUserLevel from "@/components/client/board/board-user-level.vue";
import BoardRecommend from "@/components/client/board/board-recommend.vue";
import IconBookmark from "@/components/client/icons/icon-bookmark.vue";
import IconShare from "@/components/client/icons/icon-share.vue";

export default {
    components: {
        BoardTit,
        BoardCount,
        BoardComment,
        BoardUserLevel,
        BoardRecommend,
        IconBookmark,
        IconShare,
    },
    async created() {
        await this.init();
    },
    data() {
        return {
            board: null,
        };
    },
    methods: {
        async init() {
            let { board } = await api.v1.boards.get({ _id: this._board });
            let youtubeContent = this.replaceAllYouTubeLinks(board.content)
            youtubeContent = this.replaceYouTubeLink(youtubeContent)
            board.content = youtubeContent
            this.board = board;
        },
        async edit() {},
        async delete() {},
        async like() {
            let { board } = await api.v1.boards.like({ _id: this.board._id });
            this.board = board;
        },
        async disLike() {
            let { board } = await api.v1.boards.disLike({ _id: this.board._id });
            this.board = board;
        },
        replaceAllYouTubeLinks(str) {
            // 검색할 문자열
            const searchStr = "https://www.youtube.com/watch?v=";

            // <a> 태그와 </a> 태그 사이의 문자열을 검색하여 추출함
            const regex = /<a\b[^>]*>(.*?)<\/a>/gi;
            let matchedStr = regex.exec(str);
            let newStr = str;

            // 문자열에서 검색 대상 문자열이 포함되어 있을 때까지 반복함
            while (matchedStr) {
                // 검색 대상 문자열에서 검색할 문자열의 위치를 찾음
                const startIndex = matchedStr[0].indexOf(searchStr);
                if (startIndex !== -1) {
                const endIndex = startIndex + searchStr.length;
                const videoId = matchedStr[0].substring(endIndex, endIndex + 11);
                // 11자리 YouTube 동영상 ID 값을 추출함

                // iframe 태그를 생성하고 YouTube 동영상 ID 값을 삽입함
                const iframeHtml = '<div class="iframe"><div class="iframe__inner"><iframe src="https://www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe></div></div>';

                // 검색 대상 문자열에서 <a> 태그와 </a> 태그 사이의 문자열을 iframe 태그로 교체함
                newStr = newStr.replace(matchedStr[0], iframeHtml);
                }
                matchedStr = regex.exec(str);
            }

            return newStr;
        },

        replaceYouTubeLink(str) {
            // 검색할 문자열
            const searchStr = "https://youtu.be/";

            // <a> 태그와 </a> 태그 사이의 문자열을 검색하여 추출함
            const regex = /<a\b[^>]*>(.*?)<\/a>/gi;
            let matchedStr = regex.exec(str);
            let newStr = str;

            // 문자열에서 검색 대상 문자열이 포함되어 있을 때까지 반복함
            while (matchedStr) {
                // 검색 대상 문자열에서 검색할 문자열의 위치를 찾음
                const startIndex = matchedStr[0].indexOf(searchStr);
                if (startIndex !== -1) {
                const videoId = matchedStr[0].substring(startIndex + searchStr.length, startIndex + searchStr.length + 11);
                // iframe 태그를 생성하고 동영상 ID 값을 삽입함
                const iframeHtml = '<div class="iframe"><div class="iframe__inner"><iframe src="https://www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe></div></div>';

                // 검색 대상 문자열에서 <a> 태그와 </a> 태그 사이의 문자열을 iframe 태그로 교체함
                newStr = newStr.replace(matchedStr[0], iframeHtml);
                }
                matchedStr = regex.exec(str);
            }

            return newStr;
        },
        set(board) {
            let youtubeContent = this.replaceAllYouTubeLinks(board.content)
            youtubeContent = this.replaceYouTubeLink(youtubeContent)
            board.content = youtubeContent
            this.board = board;
        }
    },
    computed: {
        _board() {
            return this.$route.params._board;
        },
    },
};
</script>

<style lang="scss" scoped></style>
